<template>
    <div style="width:100vw;height:100vh;font-weight: 600;background: #F6F6F6;position: relative;">
        <headTitle :title="title" class="title"></headTitle>
        <div
            style="position: absolute;top: 0;right: 0;bottom: 0;left: 0;margin: auto;display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <span style="text-align:center;font-size: 32px;">404 NoFound</span>
            <div>
                对不起，您没有权限进入该页面
            </div>
        </div>


    </div>
</template>

<script>
import headTitle from '../components/headTitle.vue';
export default {
    name: 'noFound',
    components: {
        headTitle
    },
    data() {
        return {
            title: '没有找到该页面'
        }
    }
}
</script>

<style scoped>
.title {
    z-index: 2;
}
</style>